
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "2px",
        },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Trainee Name",
          key: "trainee_name",
          sortable: true,
        },
        {
          name: "Mobile",
          key: "mobile",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "File",
          key: "file",
          sortable: true,
        },
        {
          name: "Last Update Time",
          key: "last_update",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          ref_no: "	SEIP-AEOSIB-ABC-EN-T2-N02-001",
          reg_no: "2600009222",
          trainee_name: "Md. Rejowan Hossain",
          mobile: "01862330882",
          gender: "Male",
          file: " ",
          last_update: "2022-02-07",
        },
        {
          id: 2,
          ref_no: "	SEIP-AEOSIB-ABC-EN-T2-N02-003",
          reg_no: "2600009223",
          trainee_name: "Md. Abdur Rahman",
          mobile: "01866456028",
          gender: "Male",
          file: " ",
          last_update: "2022-02-07",
        },
        {
          id: 3,
          ref_no: "	SEIP-AEOSIB-ABC-EN-T2-N02-004",
          reg_no: "2600009224",
          trainee_name: "Md Saifuddin",
          mobile: "01840586136",
          gender: "Male",
          file: " ",
          last_update: "2022-02-07",
        },
        {
          id: 4,
          ref_no: "	SEIP-AEOSIB-ABC-EN-T2-N02-005",
          reg_no: "2600009225",
          trainee_name: "Mohammad Ashraful Alam",
          mobile: "01860013985",
          gender: "Male",
          file: " ",
          last_update: "2022-02-07",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
